import * as React from "react";
import Header from "../components/Header";
import Seo from "../components/Seo";
import { MDX, MDXProps } from "../components/Mdx";
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";
import { GetInTouch } from "../components/ActionButtons";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import { useEffect } from "react";
import gsap from "gsap";
import { LogoGrid } from "../components/Logos";
import { Link } from "gatsby";
import SectionHeading from "../components/SectionHeading";
import onestopshopImage from "../assets/img/home/onestopshop.png";
import owndataImage from "../assets/img/home/owndata.png";
import globalsolutionImage from "../assets/img/home/globalsolution.png";
import pricingImage from "../assets/img/home/pricing.png";
import presalesImage from "../assets/img/home/presales.png";
import campaignImage from "../assets/img/home/campaign.png";
import starsIcons from "../assets/svg/testimonialstars.svg";
import ticketing from "../assets/svg/ticketing.svg";
import globe from "../assets/svg/globe.svg";
import charts from "../assets/svg/charts.svg";
import fanclub from "../assets/svg/fanclub.svg";
import fandata from "../assets/svg/fandata.svg";
import bundles from "../assets/svg/bundles.svg";
import tap from "../assets/svg/tap.svg";
import SectionCard from "../components/SectionCard";
import { Draw } from "../components/Draw";
import TestimonialCard from "../components/TestimonialCard";
import FeatureCard from "../components/FeatureCard";

type MDXNochild = Omit<MDXProps, "children">;

export interface ArticleLayout extends MDXNochild {
  data?: Array<{ node: ArticleProps }>;
  title: string;
  description: string;
}

export interface ArticleProps {
  id: React.Key;
  anchor?: string;
  slug?: string;
  image?: IGatsbyImageData;
  title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
  frontmatter: MDXProps["frontmatter"] & { slug?: string };
  body: string;
}

const ArticleRowsHome = ({ data, title, description }: ArticleLayout) => {
  const articles =
    data?.map(({ node }) => {
      const { body, id, frontmatter } = node;
      const title = frontmatter!.title;
      const anchor = frontmatter!.anchor;
      const image = getImage(frontmatter!.image);

      return { id, title, body, frontmatter, image, anchor };
    }) ?? [];

  useEffect(() => {
    const animatedUp = gsap.utils.toArray(".AnimatedUp") as HTMLElement[];

    animatedUp.forEach((el) => {
      gsap.from(el, {
        duration: 1,
        opacity: 0,
        y: 100,
        ease: "power3.out",
        scrollTrigger: {
          trigger: el,
        },
      });
    });
  }, []);

  return (
    <>
      <Seo title={title} description={description} />
      <Header />
      <div className="flex flex-col min-h-screen">
        <main className="grow flex flex-col">
          <section className="bg-white py-32 px-4 relative">
            <div className="container max-w-5xl mx-auto flex flex-col">
              <div className="flex flex-col items-center gap-8">
                <SectionHeading
                  subtitle="Our Platform"
                  title="A brand new eCommerce platform built for artists"
                />
                <div className="flex flex-col md:flex-row gap-6">
                  <SectionCard
                    image={onestopshopImage}
                    title="One stop shop"
                    description="Sell your tickets, merchandise and music all on one store."
                  />
                  <SectionCard
                    image={owndataImage}
                    title="Own your data"
                    description="Collect and own data from your fans."
                  />
                  <SectionCard
                    image={globalsolutionImage}
                    title="Global solution"
                    description="Increase conversion and sell more to your fans worldwide."
                  />
                </div>
                <Link to="/pricing" className="btn hover:bg-mg-yellow  hover:text-mg-purple" data-analytics-id="pricing_from_homeplatform">
                  Create your store
                </Link>
              </div>
            </div>
          </section>
          <section className="bg-gray-100 py-32 px-4 relative">
            <div className="container max-w-5xl mx-auto flex flex-col">
              <div className="flex flex-col items-center gap-8">
                <SectionHeading
                  subtitle="What we offer"
                  title="Created for the Music Industry"
                />
                <div className="flex flex-col md:flex-row gap-6">
                  <SectionCard
                    image={campaignImage}
                    title="Campaign Management"
                    description="Album pre-order and ticket pre-sale campaigns."
                  />
                  <SectionCard
                    image={presalesImage}
                    title="Ticket Pre-sales"
                    description="Direct to Fan ticket pre-sale for UK, Europe and North America tours."
                  />
                  <SectionCard
                    image={pricingImage}
                    title="Flexible Pricing"
                    description="Flexible pricing plans, tailored to your needs."
                  />
                </div>
                <Link to="/pricing" className="btn hover:bg-mg-yellow hover:text-mg-purple" data-analytics-id="pricing_from_homeoffer">
                  Create your store
                </Link>
              </div>
            </div>
          </section>
          <section className="bg-white py-32 px-4 relative">
            <div className="container max-w-4xl mx-auto flex flex-col">
              <div className="flex flex-col items-center">
                <SectionHeading
                  subtitle="Features"
                  title="Shaping the future of Music eCommerce"
                />
                <div className="grid grid-col-1 sm:grid-cols-2 gap-6 p-3">
                  <FeatureCard 
                    color="green" 
                    icon={globe} 
                    title="Global Solution & Fulfilment"
                    text="Sell to your fans internationally and use our global warehouse network."          
                  />
                  <FeatureCard 
                    color="yellow" 
                    icon={ticketing} 
                    title="Ticketing"
                    text="Sell directly to your fans, with low booking fees."          
                  />
                </div>
                <div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-4">
                  <FeatureCard 
                    color="red" 
                    icon={charts} 
                    title="International Chart Eligibility"
                    text="Ideal for album preorder campaigns, we chart report your album sales."          
                  />
                  <FeatureCard 
                    color="yellow" 
                    icon={fanclub} 
                    title="Fan Club Memberships"
                    text="Reward your loyal fans with exclusive products, discounts and rewards."          
                  />
                  <FeatureCard 
                    color="green" 
                    icon={fandata} 
                    title="Fan Data Ownership"
                    text="All customer data collected through the store belongs to the artist and their team."          
                  />
                </div>
                <div className="grid grid-col-1 sm:grid-cols-2 gap-6 p-3 mb-4">
                  <FeatureCard 
                    color="green" 
                    icon={tap} 
                    title="Ticket Access Pass"
                    text="Maximise your album sales with our preorder the album to get access to the ticket presale unique feature."          
                  />
                  <FeatureCard 
                    color="red" 
                    icon={bundles} 
                    title="Bundles & Upsells"
                    text="Bundle music, merch and tickets together."          
                  />
                </div>
                <Link to="/pricing" className="btn hover:bg-mg-yellow hover:text-mg-purple" data-analytics-id="pricing_from_homefeatures">
                  Create your store
                </Link>
              </div>
            </div>
          </section>
          <section className="bg-gray-100 py-32 px-4 relative">
            <div className="container max-w-5xl mx-auto flex flex-col">
              <div className="flex flex-col items-center gap-8">
                <SectionHeading
                  subtitle="Testimonials"
                  title="A solution praised by Industry leaders"
                />
                <div className="flex flex-col md:flex-row gap-6">
                  <div className="flex-1 flex flex-col gap-6">
                    <TestimonialCard 
                      color="green"
                      image={starsIcons}
                      quote='We have used Music Glue Ticketing for artists across our entire roster over the last 10+ years. Music Glue’s low booking fees, seamless customer experience and transparency help us to live-out our fan-centric values.'
                      name="Rowan Brand" 
                      job="Tribe Management (Bear’s Den, Amber Run, Charlie Cunningham)"
                    />
                  </div>
                  <div className="flex-1 flex flex-col gap-6">
                    <TestimonialCard 
                      color="purple"
                      image={starsIcons}
                      quote="Music Glue offered a great solution for us to tie in our existing album pre-order campaign with James Blunt's 2024 arena tour ticket fan pre-sale. Their ticket access pass feature is innovative, secure, and customer-friendly, and allowed us to significantly increase our album pre-order sales in a straight-forward manner. Plus it gives us the flexibility to continue to offer existing customers even more offers in the future if we need!"
                      name="Erik Nielsen" 
                      job="Wingnut Music (James Blunt)"
                    />
                  </div>
                </div>
                <Link to="/pricing" className="btn hover:bg-mg-yellow hover:text-mg-purple" data-analytics-id="pricing_from_hometestimonials">
                  Create your store
                </Link>
              </div>
            </div>
          </section>
          <section className="bg-white py-32 px-4 relative">
            <div className="container max-w-4xl mx-auto flex flex-col">
              <div className="flex flex-col md:flex-row items-start">
                <div className="basis-1/3">
                  <div className="max-w-xl mx-auto flex flex-col items-start gap-1 p-6">
                    <span className="text-mg-green uppercase font-semibold">FAQ</span>
                    <h2 className="text-3xl md:text-5xl text-mg-purple text-left md:leading-tight mb-2">Got a question?</h2>
                    <p>Here is a list of our most frequently asked questions. Still have a question? <a href="/business-enquiries">Contact us here</a>.</p>
                  </div>
                </div>
                <div className="basis-2/3 flex flex-col gap-0 p-4 w-full">
                  <Draw 
                    title="Does Music Glue use Shopify?" 
                    id="checkout-help"
                    children="No, Music Glue has its own in-house e-commerce platform, tailored to the music industry."
                    className="w-full"
                  />
                  <Draw 
                    title="Is Music Glue a Merch company?" 
                    id="checkout-help"
                    children="No, Music Glue provides you with the e-commerce platform to sell your merch but doesn’t manufacture merch. You can source it via your own channels or speak to us if you need recommendations."
                    className="w-full"
                  />
                  <Draw 
                    title="Is Music Glue a fulfilment company?" 
                    id="checkout-help"
                    children="No, however we do have warehouse partners in the UK, USA, Australia, Mainland Europe whom we have a full integration with. Alternatively, you can use your own fulfilment partner or self-fulfil your orders."
                    className="w-full"
                  />
                  <Draw 
                    title="Is Music Glue a ticket agency?" 
                    id="checkout-help"
                    children="Yes, you can use Music Glue as your main ticket agent even if your music/merch store is not powered by us."
                    className="w-full"
                  />
                  <Draw 
                    title="What is Music Glue’s ticket booking fee?" 
                    id="checkout-help"
                    children="It is one of the lowest on the market: 10% on top of the face value ticket price."
                    className="w-full"
                  />
                  <Draw 
                    title="Can I sell my merch on YouTube with Music Glue?" 
                    id="checkout-help"
                    children="Yes, please contact us for more information."
                    className="w-full"
                  />
                  <Draw 
                    title="Can I sell internationally?" 
                    id="checkout-help"
                    children="Yes, our stores are all multi-language and multi-currency. You can use our fulfilment partners to ship globally or self-fulfil and set your own international postage and packaging costs."
                    className="w-full"
                  />
                  <Draw 
                    title="Can I integrate my store with other services?" 
                    id="checkout-help"
                    children="Yes, we have multiple integrations for different needs. To name a few: GTM, Meta, Mailchimp, Klaviyo, Brevo, Zapier. We can also provide product feeds that can be loaded into Google Merchant Centre and Meta catalogues."
                    className="w-full"
                  />
                </div>
                {/* <Link to="/pricing" className="btn hover:bg-white hover:text-mg-purple">
                  Create your store
                </Link> */}
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ArticleRowsHome;
